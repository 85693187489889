export const communityMap: any = {
  Arizona: {
    'us-az': {
      id: 'us-az',
      name: 'State of Arizona',
      bitlyTag: 'AZ_ASU',
    },
    'us-az-academy-village': {
      id: 'us-az-academy-village',
      name: 'Academy Village',
      bitlyTag: 'AZ_AcademyVillage',
    },
    'us-az-apache': {
      id: 'us-az-apache',
      name: 'Apache County',
      bitlyTag: 'AZ_Apache',
    },
    'us-az-asu': {
      id: 'us-az-asu',
      name: 'Arizona State University',
      bitlyTag: 'AZ_State',
    },
    'us-az-cochise': {
      id: 'us-az-cochise',
      name: 'Cochise County',
      bitlyTag: 'AZ_Cochise',
    },
    'us-az-coconino': {
      id: 'us-az-coconino',
      name: 'Coconino County',
      bitlyTag: 'AZ_Coconino',
    },
    'us-az-fair': {
      id: 'us-az-fair',
      name: 'Fort Apache Indian Reservation',
      bitlyTag: 'AZ_FAIR',
    },
    'us-az-gila': {
      id: 'us-az-gila',
      name: 'Gila County',
      bitlyTag: 'AZ_Gila',
    },
    'us-az-graham': {
      id: 'us-az-graham',
      name: 'Graham County',
      bitlyTag: 'AZ_Graham',
    },
    'us-az-greenlee': {
      id: 'us-az-greenlee',
      name: 'Greenlee County',
      bitlyTag: 'AZ_Greenlee',
    },
    'us-az-la-paz': {
      id: 'us-az-la-paz',
      name: 'La Paz County',
      bitlyTag: 'AZ_LaPaz',
    },
    'us-az-maricopa': {
      id: 'us-az-maricopa',
      name: 'Maricopa County',
      bitlyTag: 'AZ_Maricopa',
    },
    'us-az-mohave': {
      id: 'us-az-mohave',
      name: 'Mohave County',
      bitlyTag: 'AZ_Mohave',
    },
    'us-az-nau': {
      id: 'us-az-nau',
      name: 'Northern Arizona University',
      bitlyTag: 'AZ_NAU',
    },
    'us-az-navajo': {
      id: 'us-az-navajo',
      name: 'Navajo County',
      bitlyTag: 'AZ_Navajo',
    },
    'us-az-pima': {
      id: 'us-az-pima',
      name: 'Pima County',
      bitlyTag: 'AZ_Pima',
    },
    'us-az-pinal': {
      id: 'us-az-pinal',
      name: 'Pinal County',
      bitlyTag: 'AZ_Pinal',
    },
    'us-az-santa-cruz': {
      id: 'us-az-santa-cruz',
      name: 'Santa Cruz County',
      bitlyTag: 'AZ_SantaCruz',
    },
    'us-az-uoa': {
      id: 'us-az-uoa',
      name: 'University of Arizona',
      bitlyTag: 'AZ_UofA',
    },
    'us-az-yavapai': {
      id: 'us-az-yavapai',
      name: 'Yavapai County',
      bitlyTag: 'AZ_Yavapai',
    },
    'us-az-yuma': {
      id: 'us-az-yuma',
      name: 'Yuma County',
      bitlyTag: 'AZ_Yuma',
    },
  },
  Bermuda: {
    bm: {
      id: 'bm',
      name: 'Residents',
      bitlyTag: 'BM_Res',
    },
    'bm-tourists': {
      id: 'bm-tourists',
      name: 'Visitors',
      bitlyTag: 'BM_Vis',
    },
  },
};

export const organizationName = {
  arizona: 'Arizona',
};

export const analyticsEventNameToLabel = new Map([
  ['WH_ExposureNotificationSent_Low', 'Low exposure notification'],
  ['WH_ExposureNotificationSent_High', 'High exposure notification'],
  ['WH_PositiveDiagnosis_Shared', 'Positive diagnosis'],
  ['WH_ShareButton_Clicked', 'Share button clicked'],
  ['WH_AppAnalytics_OptIn', 'Opt-in'],
  ['WH_AppAnalytics_OptOut', 'Opt-out'],
  ['WH_PositiveDiagnosis_Deleted', 'Positive diagnosis deleted'],
]);

export const clickEventTagToLabel = new Map([
  ['NoExp', 'No Exposure'],
  ['LowExp', 'Low Exposure'],
  ['HighExp', 'High Exposure'],
  ['Pos', 'Positive Diagnosis'],
]);

export const appStoreMap = new Map([
  ['apple', 'iOS'],
  ['google_play', 'Android'],
]);

export const bitlyColors = [
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#bc80bd',
];

export const safeColors = [
  '#543005',
  '#8c510a',
  '#bf812d',
  '#dfc27d',
  '#80cdc1',
  '#35978f',
  '#01665e',
  '#003c30',
  '#543005',
  '#8c510a',
  '#bf812d',
  '#dfc27d',
  '#80cdc1',
  '#35978f',
  '#01665e',
  '#003c30',
];
