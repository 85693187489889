import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { QueryConfig } from 'react-query';
import { CustomTooltipWrapper } from './styles';
import { communityMap, clickEventTagToLabel, appStoreMap, analyticsEventNameToLabel } from './data';

export const useQuerySettings: QueryConfig<any, any> = {
  cacheTime: 30 * 60 * 1000, // 30 minutes
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const fillZeroItems = (data: any, itemClosure: Function) => {
  let time = moment();
  for (let days = 1; days <= 90; days += 1) {
    if (days > data.length) {
      data.unshift(itemClosure(time));
      continue;
    }

    const actualDate = data[data.length - days].date;
    const expectedDate = time.format('l');

    if (actualDate !== expectedDate) {
      // Covers the case when the data is in the "future" compared to the local time.
      // Hapens when it's a next day in UTC but the local time is still current day.
      if (moment(actualDate).isBefore(moment(expectedDate))) {
        data.splice(data.length - (days - 1), 0, itemClosure(time));
      }
    }

    time = time.subtract(1, 'days');
  }
};

// TODO: add more generic way to handle this
// https://wehealth.atlassian.net/browse/APP-1849
export const getCommunityName = (id: string, organizationName: string, property: string = 'name'): string => {
  const _commap = communityMap[organizationName];
  if (!_commap) return id;

  const _item = _commap[id];
  if (!_item) return id;

  return _item[property];
};

export const findCommunityName = (id: string, organizationName: string, search?: string): string => {
  const _commap = communityMap[organizationName];
  if (!_commap) return id;

  const _items = _.filter(_commap, { [search || 'id']: id });
  const _item = _items[0];

  if (!_item) return id;

  return _item.name;
};

export const CustomTooltip = (obj: any) => {
  const { active, payload }: { active: any; payload: any } = obj;

  if (!active || !payload) return null;

  let total: string;
  let getColor: (name: string) => string;
  let payloadData: [] = [];

  if (payload.length) {
    payloadData = payload[0].payload;

    const numOr0 = (n: any) => (isNaN(n) ? 0 : n);

    const sumValues = (obj: any) => Object.values(obj).reduce((a, b) => numOr0(a) + numOr0(b));
    const colors: any = [];

    payload.map((p: any) => colors.push({ color: p.fill, name: p.name }));

    getColor = (name: string) => {
      const color = _.filter(colors, (o) => {
        return o.name === name;
      });

      if (color.length) {
        return color[0].color;
      }
      return '';
    };
    total = `${sumValues(payloadData)}`;
  } else {
    total = '0';
  }

  return (
    <CustomTooltipWrapper>
      <p className="label">{`Total : ${total}`}</p>
      {Object.keys(payloadData).map((o, i) => {
        return <p style={{ color: getColor(o) }} key={i}>{`${o}: ${Object.values(payloadData)[i]}`}</p>;
      })}
    </CustomTooltipWrapper>
  );
};

export const getKeysForVerificationCodes = (data: any) => {
  let appKeys: any = _.groupBy(data, function (item) {
    return item.app;
  });

  appKeys = Object.keys(appKeys).sort();
  return appKeys;
};

export const getKeysForCommunities = (data: any): string[] => {
  const cleanData = _.filter(data, (i) => {
    return i.clicks !== '0';
  });

  const clicksKeys = _.groupBy(cleanData, (item) => {
    return item.community_code;
  });

  return Object.keys(clicksKeys).sort();
};

export const getKeysForActiveUsers = (data: any): string[] => {
  const cleanData = _.filter(data, (i) => {
    return i.active_users !== 0;
  });

  const keys = _.groupBy(cleanData, (item) => {
    return item.community;
  });

  return Object.keys(keys).sort();
};

export const getKeysForOptIn = (data: any): string[] => {
  const clicksKeys = _.groupBy(data, (item) => {
    return item.sub_region_name;
  });

  return Object.keys(clicksKeys).sort();
};

export const getKeysForPositiveCases = (data: any): string[] => {
  const keys = _.groupBy(data, (item) => {
    return item.community_id;
  });

  return Object.keys(keys).sort();
};

// Formatters

export const formatVerificationCodes = (data: any): any[] => {
  // Format data
  const groupedByDate: any = _.groupBy(data, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      tempObj[`${item.app} Issued`] = Number(item.codes_issued);
      tempObj[`${item.app} Claimed`] = Number(item.codes_claimed);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyVerificationCodeItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`Issued`] = 0;
    tempObj[`Claimed`] = 0;
    return tempObj;
  }

  fillZeroItems(formattedData, emptyVerificationCodeItem);

  return formattedData;
};

export const formatActiveUsers = (
  data: any,
  activeUserKeys: string[],
  communityId: string,
  organizationName: string
): any[] => {
  const filterFunc = (i: any) => {
    return i.active_users !== 0 && (i.community === communityId || !activeUserKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = getCommunityName(item.community, organizationName);
      // const communityName = item.community;

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.active_users);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyActiveUsersCommunityItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['active_users'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyActiveUsersCommunityItem);

  return formattedData;
};

export const formatPositiveCases = (
  data: any,
  keys: string[],
  communityId: string,
  organizationName: string
): any[] => {
  const filterFunc = (i: any) => {
    return i.value !== 0 && (i.community_id === communityId || !keys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = getCommunityName(item.community_id, organizationName);

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.value);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyPositiveCasesItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['Cases'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyPositiveCasesItem);

  return formattedData;
};

export const formatClicksByCommunity = (
  data: any,
  communityKeys: string[],
  communityId: string,
  organizationName: string
): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.community_code === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      const communityName = findCommunityName(item.community_code, organizationName, 'bitlyTag');

      // take any already logged value
      let value = tempObj[communityName] || 0;

      // add the value from the new event
      tempObj[communityName] = value + Number(item.clicks);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyClicksByCommunityItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj['Clicks'] = 0;

    return tempObj;
  }

  fillZeroItems(formattedData, emptyClicksByCommunityItem);

  return formattedData;
};

export const formatClicksByScreen = (data: any): any[] => {
  const cleanData = data;

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  function addClick(tempObj: any, item: any, key: string | undefined) {
    if (key == undefined) return;

    // take any already logged value
    let value = tempObj[key] || 0;
    // add the value from the new event
    tempObj[key] = value + Number(item.clicks);
  }

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      addClick(tempObj, item, clickEventTagToLabel.get(item.application_code));
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyClicksByScreenItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');

    // this is needed to zero-fill the data
    // tooltip is hidden without the same keys
    clickEventTagToLabel.forEach((elem: any) => {
      tempObj[elem] = 0;
    });

    return tempObj;
  }

  fillZeroItems(formattedData, emptyClicksByScreenItem);

  return formattedData;
};

export const formatAnalyticsData = (data: any, communityKeys: string[], communityId: string): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.sub_region_name === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  function addEvent(tempObj: any, item: any, key: string | undefined) {
    if (key == undefined) return;

    // take any already logged value
    let value = tempObj[key] || 0;
    // add the value from the new event
    tempObj[key] = value + Number(item.event_value);
  }

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      addEvent(tempObj, item, analyticsEventNameToLabel.get(item.event_name));
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAnalyticsItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No events`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAnalyticsItem);

  return formattedData;
};

export const formatAnalyticsByCommunity = (data: any, communityKeys: string[], communityId: string): any[] => {
  const filterFunc = (i: any) => {
    return i.clicks !== '0' && (i.sub_region_name === communityId || !communityKeys.includes(communityId));
  };

  const cleanData = _.filter(data, filterFunc);

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.date.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].date.value, 'YYYY-MM-DD').format('l');
    _.forEach(apps, (item) => {
      // take any already logged value
      let value = tempObj[item.sub_region_name] || 0;
      // add the value from the new event
      tempObj[item.sub_region_name] = value + Number(item.opt_in_count);
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAnalyticsItem(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No data`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAnalyticsItem);

  return formattedData;
};

export const formatAppDownloads = (data: any): any[] => {
  const cleanData = data;

  // Format data
  const groupedByDate: any = _.groupBy(cleanData, (item) => {
    return item.start_date?.value;
  });

  const formattedData = _.map(groupedByDate, (apps) => {
    const tempObj: any = {};
    tempObj.date = moment(apps[0].start_date.value, 'YYYY-MM-DD').format('l');

    _.forEach(apps, (item) => {
      const store = appStoreMap.get(item.store) || '';
      tempObj[store] = item.downloads || 0;
    });
    return tempObj;
  });

  if (!formattedData.length) return formattedData;

  // Prefill with empty items for absent days
  function emptyAppDownloads(time: moment.Moment): any {
    const tempObj: any = {};
    tempObj.date = time.format('l');
    tempObj[`No data`] = '';
    return tempObj;
  }

  fillZeroItems(formattedData, emptyAppDownloads);

  return formattedData;
};
