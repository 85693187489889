import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './utils';
import { bitlyColors } from './data';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const AppDownloads = ({ range, appDownloadsRange }: { range: number; appDownloadsRange: any }) => {
  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>App Downloads (Last {range} Days)</ChartHeading>
        <ChartSubHeading>Daily App Downloads</ChartSubHeading>
        {!appDownloadsRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={420}>
            <BarChart
              barGap={0}
              data={appDownloadsRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              <Bar key="iOS" barSize={20} dataKey="iOS" stackId="a" fill={bitlyColors[1]} />
              <Bar key="Android" barSize={20} dataKey="Android" stackId="a" fill={bitlyColors[0]} />

              <Bar dataKey={`No data`} stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default AppDownloads;
