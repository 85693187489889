import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './utils';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const ClicksByAppScreen = ({ range, clicksByScreenRange }: { range: number; clicksByScreenRange: any }) => {
  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Clicks by App Screen (Last {range} Days)</ChartHeading>

        <ChartSubHeading>
          When a user clicks on a link within the app it can be reported on in the aggregate. This shows how those
          clicks break down by the various app screens the user can be viewing. E.g. “High Exposure” shows how many
          clicks were made on the screen users see when they get a High Exposure alert. This doesn't require opt-in so
          the data is representative of the entire user base.
        </ChartSubHeading>
        {!clicksByScreenRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={320}>
            <BarChart
              barGap={0}
              data={clicksByScreenRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              <Bar barSize={20} dataKey="No Exposure" stackId="a" fill="#abdda4" />
              <Bar barSize={20} dataKey="Low Exposure" stackId="a" fill="#fdae61" />
              <Bar barSize={20} dataKey="High Exposure" stackId="a" fill="#d53e4f" />
              <Bar barSize={20} dataKey="Positive Diagnosis" stackId="a" fill="#9e0142" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default ClicksByAppScreen;
