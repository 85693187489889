import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useParams } from 'react-router-dom';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getCommunityName, CustomTooltip } from './utils';
import { bitlyColors, safeColors } from './data';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const CodesClaimedPercent = ({
  organization,
  range,
  percentPosCodesClaimedRange,
  positiveCaseKeys,
}: {
  organization: any;
  range: number;
  percentPosCodesClaimedRange: any;
  positiveCaseKeys: any;
}) => {
  const { community: communityId }: any = useParams();

  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Codes Claimed (% of total pos cases, last {range} Days)</ChartHeading>

        <ChartSubHeading>Codes Claimed</ChartSubHeading>
        {!percentPosCodesClaimedRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={320}>
            <BarChart
              barGap={0}
              data={percentPosCodesClaimedRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              {positiveCaseKeys.includes(communityId) ? (
                <Bar
                  key={communityId}
                  barSize={20}
                  dataKey={getCommunityName(communityId, organization?.name)}
                  stackId="a"
                  fill={safeColors[0]}
                />
              ) : (
                positiveCaseKeys.map((label: string, index: number) => (
                  <Bar
                    key={label}
                    barSize={20}
                    dataKey={getCommunityName(label, organization?.name)}
                    stackId="a"
                    fill={bitlyColors[index]}
                  />
                ))
              )}
              <Bar dataKey={'Cases'} stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default CodesClaimedPercent;
