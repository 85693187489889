import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './utils';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const OptInByEvent = ({ range, analyticsDataRange }: { range: number; analyticsDataRange: any }) => {
  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Opt-in Analytics by Event (Last {range} Days)</ChartHeading>
        <ChartSubHeading>
          The app allows users to explicitly opt-in to sharing anonymous analytics. This chart shows the total number of
          analytics events aggregated by the type of event. Since this requires opt-in the activity here is just a
          sample of the entire user base.
        </ChartSubHeading>
        {!analyticsDataRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={320}>
            <BarChart
              barGap={0}
              data={analyticsDataRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar barSize={20} dataKey="Low exposure notification" stackId="a" fill="#fdae61" />
              <Bar barSize={20} dataKey="High exposure notification" stackId="a" fill="#d53e4f" />
              <Bar barSize={20} dataKey="Positive diagnosis" stackId="a" fill="#9e0142" />
              <Bar barSize={20} dataKey="Share button clicked" stackId="a" fill="#5e4fa2" />
              <Bar barSize={20} dataKey="Opt-in" stackId="a" fill="#66c2a5" />
              <Bar barSize={20} dataKey="Opt-out" stackId="a" fill="#1a9850" />
              <Bar barSize={20} dataKey="Positive diagnosis deleted" stackId="a" fill="#3288bd" />
              <Bar barSize={20} dataKey="No events" stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default OptInByEvent;
