import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const CommunitySelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 15px 15px 0;
`;
export const CommunitySelectWrapper = styled.div``;
export const CommunitySelect = styled.select``;
export const CommunityOption = styled.option``;

export const ChartHeading = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
`;

export const ChartSubHeading = styled.p`
  font-weight: 100;
  font-size: 14px;
  margin-left: 20px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
export const CustomTooltipWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border: 1px solid lightgray;
  z-index: 100;
`;
