import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid } from '@material-ui/core';

import { Redirect, useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useQuery } from 'react-query';
import * as _ from 'lodash';

import * as ROUTES from '@src/constants/routes';
import {
  getAppAnalytics,
  getBitlyClicks,
  getExposureNotifications,
  getVerificationCodes,
  getPositiveCases,
  getActiveUsers,
  getAppDownloads,
} from '@src/apis/analytics';

import { useInject } from '@src/utils/hooks/mobx';

import PageTitle from '@src/components/PageTitle';

import {
  useQuerySettings,
  getKeysForActiveUsers,
  getKeysForCommunities,
  getKeysForOptIn,
  getKeysForPositiveCases,
  getKeysForVerificationCodes,
  formatActiveUsers,
  formatAnalyticsByCommunity,
  formatAnalyticsData,
  formatAppDownloads,
  formatClicksByCommunity,
  formatClicksByScreen,
  formatPositiveCases,
  formatVerificationCodes,
} from './Dashboard/utils';
import { CommunitySelectContainer, CommunitySelectWrapper, CommunitySelect, CommunityOption } from './Dashboard/styles';
import { organizationName, communityMap } from './Dashboard/data';
import DailyActiveUsers from './Dashboard/DailyActiveUsers';
import ClicksByAppScreen from './Dashboard/ClicksByAppScreen';
import ClicksByCommunity from './Dashboard/ClicksByCommunity';
import PositiveCases from './Dashboard/PositiveCases';
import OptInByEvent from './Dashboard/OptInByEvent';
import OptInByCommunity from './Dashboard/OptInByCommunity';
import VerificationCodeBySource from './Dashboard/VerificationCodeBySource';
import PositiveCasesByCommunity from './Dashboard/PositiveCasesByCommunity';
import CodesClaimedPercent from './Dashboard/CodesClaimedPercent';
import AppDownloads from './Dashboard/AppDownloads';

const mapStore = ({ user, organization }: { user: any; organization: any }) => ({
  user,
  organization,
});

const Dashboard = observer(() => {
  const history = useHistory();
  const { community: communityId }: any = useParams();
  const [communityIndexActiveUsers, setCommunityIndexActiveUsers] = useState<number>(0);

  const { user, organization } = useInject(mapStore);

  const [range, setRange] = useState(30);

  const [verificationCodes, setVerificationCodes] = useState<any[]>([]);
  const [verificationCodesRange, setVerificationCodesRange] = useState<any[]>([]);
  const [verificationCodeKeys, setVerificationCodeKeys] = useState([]);

  const [positiveCases, setPositiveCases] = useState<any[]>([]);
  const [positiveCasesRange, setPositiveCasesRange] = useState<any[]>([]);
  const [positiveCaseKeys, setPositiveCaseKeys] = useState<any[]>([]);

  const [percentPosCodesClaimed, setPercentPosCodesClaimed] = useState<any[]>([]);
  const [percentPosCodesClaimedRange, setPercentPosCodesClaimedRange] = useState<any[]>([]);

  const [percentPosCases, setPercentPosCases] = useState<any[]>([]);
  const [percentPosCasesRange, setPercentPosCasesRange] = useState<any[]>([]);

  const [analyticsData, setAnalyticsData] = useState<any[]>([]);
  const [analyticsDataRange, setAnalyticsDataRange] = useState<any[]>([]);

  const [clicksByScreen, setClicksByScreen] = useState<any[]>([]);
  const [clicksByScreenRange, setClicksByScreenRange] = useState<any[]>([]);

  const [activeUsers, setActiveUsers] = useState<any[]>([]);
  const [activeUsersRange, setActiveUsersRange] = useState<any[]>([]);
  const [activeUserKeys, setActiveUserKeys] = useState<string[]>([]);

  const [clicksByCommunity, setClicksByCommunity] = useState<any[]>([]);
  const [clicksByCommunityRange, setClicksByCommunityRange] = useState<any[]>([]);
  const [communityKeys, setCommunityKeys] = useState<string[]>([]);

  const [analyticsByCommunity, setAnalyticsByCommunity] = useState<any[]>([]);
  const [analyticsByCommunityRange, setAnalyticsByCommunityRange] = useState<any[]>([]);
  const [analyticsByCommunityKeys, setAnalyticsByCommunityKeys] = useState<string[]>([]);

  const [appDownloads, setAppDownloads] = useState<any[]>([]);
  const [appDownloadsRange, setAppDownloadsRange] = useState<any[]>([]);

  const { data: positiveCasesData, isLoading: isLoadingPositiveCases } = useQuery(
    ['positive_cases', communityId],
    () => getPositiveCases(organization.name, communityId),
    useQuerySettings
  );

  const { data: verificationCodesData, isLoading: isLoadingVerificationCodes } = useQuery(
    ['verification_codes', communityId],
    () => getVerificationCodes(organization.name, communityId),
    useQuerySettings
  );

  const { data: exposuresData, isLoading: isLoadingExposures } = useQuery(
    'exposureNotifications',
    () => getExposureNotifications(organization.name),
    useQuerySettings
  );

  const { data: activeUsersData, isLoading: isLoadingActiveUsers } = useQuery(
    'active-users_community',
    () => getActiveUsers(organization.name),
    useQuerySettings
  );

  const { data: clicksDataApp, isLoading: isLoadingClicksApp } = useQuery(
    ['application_code', communityId],
    () => getBitlyClicks('application_code', communityId),
    useQuerySettings
  );
  const { data: clicksDataCom, isLoading: isLoadingClicksCom } = useQuery(
    ['community_code', communityId],
    () => getBitlyClicks('community_code', communityId),
    useQuerySettings
  );

  const { data: analyticsByCommunityData, isLoading: isLoadingAnalyticsByCommunity } = useQuery(
    'opt-ins',
    () => getAppAnalytics(organization.name),
    useQuerySettings
  );

  const { data: appDownloadsData, isLoading: isLoadingAppDownloads } = useQuery(
    'app-downloads',
    () => getAppDownloads(organization.name),
    useQuerySettings
  );

  useEffect(() => {
    const _keys = getKeysForActiveUsers(activeUsersData);

    setActiveUserKeys(_keys);
    setActiveUsers(formatActiveUsers(activeUsersData, _keys, communityId, organization?.name));
    setCommunityIndexActiveUsers(_keys.indexOf(communityId) || 0);
  }, [isLoadingActiveUsers, range, communityId]);

  useEffect(() => {
    setActiveUsersRange(activeUsers.slice(-range));
  }, [activeUsers, range, communityId]);

  useEffect(() => {
    const _keys = getKeysForPositiveCases(positiveCasesData);

    setPositiveCaseKeys(_keys);
    setPositiveCases(formatPositiveCases(positiveCasesData, _keys, communityId, organization?.name));
  }, [isLoadingPositiveCases, range, communityId]);

  useEffect(() => {
    setPositiveCasesRange(positiveCases.slice(-range));
  }, [positiveCases, range, communityId]);

  useEffect(() => {
    setClicksByScreen(formatClicksByScreen(clicksDataApp));
  }, [isLoadingClicksApp, range, communityId]);

  useEffect(() => {
    setClicksByScreenRange(clicksByScreen.slice(-range));
  }, [clicksByScreen, range, communityId]);

  useEffect(() => {
    const _keys = getKeysForCommunities(clicksDataCom);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.bitlyTag;

    setCommunityKeys(_keys);
    setClicksByCommunity(formatClicksByCommunity(clicksDataCom, _keys, _communityId, organization?.name));
  }, [isLoadingClicksCom, range, communityId]);

  useEffect(() => {
    setClicksByCommunityRange(clicksByCommunity.slice(-range));
  }, [clicksByCommunity, range, communityId]);

  // Opt-in Analytics by Event
  useEffect(() => {
    const _keys = getKeysForOptIn(exposuresData);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.name;

    setAnalyticsData(formatAnalyticsData(exposuresData, _keys, _communityId));
  }, [isLoadingExposures, range, communityId]);

  useEffect(() => {
    setAnalyticsDataRange(analyticsData.slice(-range));
  }, [analyticsData, range, communityId]);

  // Opt-in Analytics by Community
  useEffect(() => {
    const _keys = getKeysForOptIn(analyticsByCommunityData);
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    const _communityId = _commap[communityId]?.name;

    setAnalyticsByCommunity(formatAnalyticsByCommunity(analyticsByCommunityData, _keys, _communityId));
    setAnalyticsByCommunityKeys(_keys);
  }, [isLoadingAnalyticsByCommunity, range, communityId]);

  useEffect(() => {
    setAnalyticsByCommunityRange(analyticsByCommunity.slice(-range));
  }, [analyticsByCommunity, range, communityId]);

  // Verification Code by Source
  useEffect(() => {
    const data = formatVerificationCodes(verificationCodesData);
    setVerificationCodes(data);
    setVerificationCodeKeys(getKeysForVerificationCodes(verificationCodesData));
  }, [isLoadingVerificationCodes, range, communityId]);

  useEffect(() => {
    setVerificationCodesRange(verificationCodes.slice(-range));
  }, [verificationCodes, range, communityId]);

  useEffect(() => {
    setAppDownloads(formatAppDownloads(appDownloadsData));
  }, [isLoadingAppDownloads, range]);

  useEffect(() => {
    setAppDownloadsRange(appDownloads.slice(-range));
  }, [appDownloads, range]);

  // % of positive cases that share a diagnosis
  useEffect(() => {
    const _commap = communityMap[organization?.name];
    if (!_commap) return;

    if (verificationCodes.length === 0 || positiveCases.length === 0) {
      setPercentPosCases([]);
      setPercentPosCodesClaimed([]);
      return;
    }

    const _name = _commap[communityId]?.name;

    const percentPosCodesClaimedData: any = [];
    const percentPosCasesData: any = [];

    const rollingDays = 7;

    positiveCases.forEach((item, i) => {
      const arrayStart = Math.max(0, i - (rollingDays - 1)); // subtract 6 days
      const arrayEnd = i + 1;
      const arrayRange = positiveCases.slice(arrayStart, arrayEnd);

      let rollingAvgPositives = 0;
      let rollingAvgClaims = 0;

      arrayRange.forEach((_item, _j) => {
        const en = _.find(verificationCodes, { date: _item.date });
        const positives = _item[_name] || 0;
        const claimedValues: any[] = Object.entries(en || {}).filter((e) => e[0]?.includes('Claimed'));
        const claimed = claimedValues.reduce((x: any, y: any) => x + y[1], 0);

        rollingAvgPositives += positives;
        rollingAvgClaims += claimed;
      });

      rollingAvgPositives = Math.round(rollingAvgPositives / rollingDays);
      rollingAvgClaims = rollingAvgClaims / rollingDays;
      rollingAvgClaims = rollingAvgClaims > rollingAvgPositives ? 0 : rollingAvgClaims;

      let percentageOfPositiveCasesClaimed = rollingAvgClaims / rollingAvgPositives;
      percentageOfPositiveCasesClaimed = Math.round(percentageOfPositiveCasesClaimed * 100);
      percentageOfPositiveCasesClaimed = isFinite(percentageOfPositiveCasesClaimed)
        ? percentageOfPositiveCasesClaimed
        : 0;

      percentPosCodesClaimedData.push({
        [_name]: percentageOfPositiveCasesClaimed,
        date: item.date,
      });

      percentPosCasesData.push({
        [_name]: rollingAvgPositives,
        date: item.date,
      });
    });

    setPercentPosCodesClaimed(percentPosCodesClaimedData);
    setPercentPosCases(percentPosCasesData);
  }, [verificationCodes, positiveCases, communityId]);

  useEffect(() => {
    setPercentPosCodesClaimedRange(percentPosCodesClaimed.slice(-range));
    setPercentPosCasesRange(percentPosCases.slice(-range));
  }, [percentPosCodesClaimed, percentPosCases]);

  const communityOnChange = async (e: any) => {
    const _communityId = e.target.value;

    if (_communityId === 'all') {
      history.push(`/dashboard`);
      return;
    }

    history.push(`/dashboard/${_communityId}`);
  };

  let egCopy =
    'E.g. “Residents” shows how many clicks were made by all the users who have chosen “Residents” as their community.';
  if (organization.name === organizationName.arizona) {
    egCopy =
      'E.g. “az_maricopa” shows how many clicks were made by all the users who have chosen Maricopa as their community.';
  }

  return !user.isSignedIn ||
    !user.isAdmin ||
    user.isFirstTimeUser ||
    (user.passwordResetRequested && user.signedInWithEmailLink) ? (
    <Redirect to={ROUTES.LANDING} />
  ) : (
    <div className="module-container" id="diagnosis-codes">
      <PageTitle title="Dashboard" />
      <h1>Dashboard (Last {range} days)</h1>

      <CommunitySelectContainer>
        {/* <CommunitySelectTitle>Select a community:</CommunitySelectTitle> */}
        <CommunitySelectWrapper>
          <CommunitySelect value={communityId} onChange={communityOnChange}>
            <CommunityOption value="all">-- Summary Dashboard --</CommunityOption>
            {communityMap[organization?.name] &&
              Object.values(communityMap[organization.name]).map((community: any) => (
                <CommunityOption value={community.id}>{community.name}</CommunityOption>
              ))}
          </CommunitySelect>
        </CommunitySelectWrapper>
      </CommunitySelectContainer>

      <ButtonGroup style={{ marginLeft: 0 }} variant="text" color="primary" aria-label="text primary button group">
        <Button
          onClick={() => {
            setRange(30);
          }}
        >
          Last 30 days
        </Button>
        <Button
          onClick={() => {
            setRange(60);
          }}
        >
          Last 60 days
        </Button>
        <Button
          onClick={() => {
            setRange(90);
          }}
        >
          Last 90 Days
        </Button>
      </ButtonGroup>

      <Grid container spacing={4}>
        <DailyActiveUsers
          range={range}
          organization={organization}
          activeUserKeys={activeUserKeys}
          activeUsersRange={activeUsersRange}
          communityIndexActiveUsers={communityIndexActiveUsers}
        />

        {positiveCasesRange.length > 0 && (
          <PositiveCases
            range={range}
            organization={organization}
            positiveCaseKeys={positiveCaseKeys}
            positiveCasesRange={positiveCasesRange}
          />
        )}

        <ClicksByAppScreen range={range} clicksByScreenRange={clicksByScreenRange} />

        <ClicksByCommunity
          range={range}
          activeUserKeys={activeUserKeys}
          clicksByCommunityRange={clicksByCommunityRange}
          communityIndexActiveUsers={communityIndexActiveUsers}
          communityKeys={communityKeys}
          organization={organization}
          egCopy={egCopy}
        />

        <OptInByEvent range={range} analyticsDataRange={analyticsDataRange} />

        <OptInByCommunity
          range={range}
          organization={organization}
          activeUserKeys={activeUserKeys}
          analyticsByCommunityKeys={analyticsByCommunityKeys}
          analyticsByCommunityRange={analyticsByCommunityRange}
          communityIndexActiveUsers={communityIndexActiveUsers}
        />

        <VerificationCodeBySource
          range={range}
          verificationCodeKeys={verificationCodeKeys}
          verificationCodesRange={verificationCodesRange}
        />

        {percentPosCasesRange.length > 0 && (
          <PositiveCasesByCommunity
            range={range}
            organization={organization}
            percentPosCasesRange={percentPosCasesRange}
            positiveCaseKeys={positiveCaseKeys}
          />
        )}

        {percentPosCodesClaimedRange.length > 0 && (
          <CodesClaimedPercent
            range={range}
            organization={organization}
            percentPosCodesClaimedRange={percentPosCodesClaimedRange}
            positiveCaseKeys={positiveCaseKeys}
          />
        )}

        <AppDownloads range={range} appDownloadsRange={appDownloadsRange} />
      </Grid>

      <div className="" style={{ marginBottom: 300 }} />
    </div>
  );
});

export default Dashboard;
