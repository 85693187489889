import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './utils';
import { safeColors } from './data';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const VerificationCodeBySource = ({
  range,
  verificationCodesRange,
  verificationCodeKeys,
}: {
  range: number;
  verificationCodesRange: any;
  verificationCodeKeys: any;
}) => {
  // Use a copy of the array and reversed it
  const safeColorReversed = safeColors.slice().reverse();

  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Verification Code by Source (Last {range} days)</ChartHeading>
        <ChartSubHeading>
          When a user tests positive they need to enter a verification code to enter the diagnosis in the app to notify
          everyone anonymously. This chart shows the total number of codes issued grouped by the source where the code
          was issued e.g. pha, labs, apps, crm etc. This data is reported from the server side and doesn't require any
          opt-in. It shows activity of the PHA and partners.
        </ChartSubHeading>
        {!verificationCodesRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={420}>
            <BarChart barGap={0} data={verificationCodesRange}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {verificationCodeKeys.map((label: string, index: number) => (
                <Bar
                  barSize={10}
                  dataKey={`${label} Issued`}
                  stackId="a"
                  fill={safeColors[index]}
                  key={`issued-${index}`}
                />
              ))}
              {verificationCodeKeys.map((label: string, index: number) => (
                <Bar barSize={10} dataKey={`${label} Claimed`} stackId="b" fill={safeColorReversed[index]} />
              ))}
              <Bar dataKey={`Issued`} stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default VerificationCodeBySource;
