import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip, findCommunityName } from './utils';
import { bitlyColors, communityMap } from './data';

import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const ClicksByCommunity = ({
  range,
  clicksByCommunityRange,
  activeUserKeys,
  communityKeys,
  organization,
  communityIndexActiveUsers,
  egCopy,
}: {
  range: number;
  clicksByCommunityRange: any;
  activeUserKeys: any;
  communityKeys: any;
  organization: any;
  communityIndexActiveUsers: any;
  egCopy: any;
}) => {
  const { community: communityId }: any = useParams();

  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Clicks by Community (Last {range} Days)</ChartHeading>
        <ChartSubHeading>
          When a user clicks on a link within the app it can be reported on in the aggregate. This shows how those
          clicks break down by the community that the user has chosen. {egCopy} This doesn't require opt-in so the data
          is representative of the entire user base.
        </ChartSubHeading>
        {!clicksByCommunityRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={420}>
            <BarChart
              barGap={0}
              data={clicksByCommunityRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              {activeUserKeys.includes(communityId) ? (
                <Bar
                  key={communityId}
                  barSize={20}
                  dataKey={communityMap[organization?.name][communityId]?.name}
                  stackId="a"
                  fill={bitlyColors[communityIndexActiveUsers]}
                />
              ) : (
                communityKeys.map((label: string, index: number) => (
                  <Bar
                    key={label}
                    barSize={20}
                    dataKey={findCommunityName(label, organization?.name, 'bitlyTag')}
                    stackId="a"
                    fill={bitlyColors[index]}
                  />
                ))
              )}
              <Bar dataKey={`Clicks`} stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default ClicksByCommunity;
