import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useParams } from 'react-router-dom';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './utils';
import { communityMap, bitlyColors, safeColors } from './data';
import { ChartHeading, ChartSubHeading, StyledPaper, LoadingWrapper } from './styles';

const OptInByCommunity = ({
  organization,
  range,
  analyticsByCommunityRange,
  activeUserKeys,
  communityIndexActiveUsers,
  analyticsByCommunityKeys,
}: {
  organization: any;
  range: number;
  analyticsByCommunityRange: any;
  activeUserKeys: any;
  communityIndexActiveUsers: any;
  analyticsByCommunityKeys: any;
}) => {
  const { community: communityId }: any = useParams();

  return (
    <Grid item xs={12}>
      <StyledPaper>
        <ChartHeading>Opt-in Analytics by Community (Last {range} Days)</ChartHeading>
        <ChartSubHeading>
          The app allows users to explicitly opt-in to sharing anonymous analytics. This chart shows the total number of
          analytics events aggregated by the community in which it occurred. Since this requires opt-in the activity
          here is just a sample of the entire user base.
        </ChartSubHeading>
        {!analyticsByCommunityRange.length ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <ResponsiveContainer width="100%" height={320}>
            <BarChart
              barGap={0}
              data={analyticsByCommunityRange}
              margin={{
                top: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                margin={{
                  top: 200,
                  bottom: 0,
                }}
              />

              {activeUserKeys.includes(communityId) ? (
                <Bar
                  key={communityMap[organization?.name][communityId]?.name}
                  barSize={20}
                  dataKey={communityMap[organization?.name][communityId]?.name}
                  stackId="a"
                  fill={bitlyColors[communityIndexActiveUsers]}
                />
              ) : (
                analyticsByCommunityKeys.map((label: string, index: number) => (
                  <Bar barSize={20} dataKey={label} stackId="a" fill={safeColors[index]} key={index} />
                ))
              )}
              <Bar barSize={20} dataKey="No data" stackId="a" legendType={'none'} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </StyledPaper>
    </Grid>
  );
};

export default OptInByCommunity;
